<template>
  <div class="search-courses">
    <search-input @changeSearch="changeSearch"/>
    <el-divider/>
  </div>
</template>

<script>
import SearchInput from "../../components/SearchInput";

export default {
  name: "SearchCourse",
  components:{
    SearchInput
  },
  mounted() {
    this.$store.commit('courses.setSearchCourseName', "")
  },
  methods: {
    changeSearch: function (search) {
      this.$store.commit('courses.setSearchCourseName', search)
    }
  }
}
</script>

<style lang="scss">
.search-courses{
  margin-bottom: 65px;
  margin-top: 20px;
  .el-divider--horizontal{
    position: absolute;
    left: 0px;
    top: 147px;
    z-index: -1;

    @media screen and (max-width: 980px) {
      top: 127px;
    }
    @media screen and (max-width: 600px) {
      top: 117px;
    }
  }

  @media screen and (max-width: 980px) {
    margin-bottom: 43px;
    padding: 0 24px;
  }
  @media screen and (max-width: 400px) {
    margin-bottom: 33px;
    padding: 0 24px;
  }
}

</style>
