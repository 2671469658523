<template>
  <form class="search-form"
        @submit.prevent="$emit('changeSearch',search)">
    <el-input class="search-form__input"
              v-model="search"
              placeholder="Поиск по курсам"
              @input="$emit('changeSearch',search)"
              @blur="$emit('changeSearch',search)"/>

    <img class="search-form__icon"
         @click="$emit('changeSearch', search)"
         src="@/assets/img/user/icon-search.svg" alt="">
  </form>
</template>

<script>
export default {
  name: "SearchInput",
  data() {
    return {
      search: ''
    }
  },
}
</script>

<style lang="scss">
.search-form {
  display: flex;
  position: relative;

  max-width: 690px;
  width: 100%;

  &__icon {
    position: absolute;
    right: 15px;
    top: 8px;

    cursor: pointer;
  }

  &__input {
    .el-input__inner {
      padding-right: 40px;
      font-family: Raleway;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;

      color: #121212;

      background: #FFFFFF;
      border: 1px solid #1454F2;
      box-sizing: border-box;
      border-radius: 100px;

      &::placeholder {
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

      }
    }
  }
}

</style>
