<template>
  <div class="courses-wrapper">
    <search-course/>
    <div class="courses-wrapper__main">
      <categories/>
      <courses-filter/>
    </div>
    <description/>

    <img class="courses-wrapper__icon-right" src="@/assets/img/user/courses/element-right.svg" alt="">
    <img class="courses-wrapper__icon-laptop" src="@/assets/img/user/courses/icon-laptop.svg" alt="">
    <img class="courses-wrapper__icon-left" src="@/assets/img/user/courses/element-left.svg" alt="">
    <img class="courses-wrapper__icon-headphones" src="@/assets/img/user/courses/icon-headphones.svg" alt="">
  </div>
</template>

<script>
import SearchCourse from "./components/SearchCourse";
import Categories from "./components/Categories";
import CoursesFilter from "./components/CoursesFilter";
import Description from "./components/Description";

export default {
  name: "Courses",
  components:{
    SearchCourse,
    CoursesFilter,
    Categories,
    Description
  }
}
</script>

<style lang="scss" scoped>
.courses-wrapper {
  display: flex;
  flex-direction: column;

  width: 100%;

  &__main{
    display: flex;
  }

  &__icon-right{
    position: absolute;
    top: -35px;
    right: 0px;
    z-index: -1;
  }
  &__icon-left{
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: -1;
  }
  &__icon-laptop{
    position: absolute;
    left: 140px;
    top: 351px;
    z-index: -1;
  }
  &__icon-headphones{
    position: absolute;
    right: 71px;
    bottom: 318px;
  }
}

@media screen and (max-width: 980px) {
  .courses-wrapper__main{
    padding: 0 24px;
  }
  .courses-wrapper__icon-right,.courses-wrapper__icon-laptop,
  .courses-wrapper__icon-left,.courses-wrapper__icon-headphones{
    display: none;
  }

  .courses-wrapper__main{
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .courses-wrapper .description-wrapper{
    display: none;
  }
}
</style>
