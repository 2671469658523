<template>
<div class="description-wrapper">
  <div class="description-wrapper__main">

    <div class="description-wrapper__main-title">
      <span>3 890</span> человек уже прошли наши
      курсы на AYA school, попробуйте и вы?
    </div>
  </div>

  <div class="description-wrapper__details">
    <div class="description-wrapper__detail">
      <img class="courses-wrapper__detail-icon" src="@/assets/img/user/home/icon-online.svg">
      <div class="description-wrapper__detail-text">Удобная образовательная онлайн-платформа</div>
    </div>
    <div class="description-wrapper__detail">
      <img class="courses-wrapper__detail-icon" src="@/assets/img/user/home/icon-file.svg">
      <div class="description-wrapper__detail-text">Домашние задания после каждого занятия</div>
    </div>
    <div class="description-wrapper__detail">
      <img class="courses-wrapper__detail-icon" src="@/assets/img/user/home/icon-progress.svg">
      <div class="description-wrapper__detail-text">Наглядный прогресс результатов обучения</div>
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: "Description"
}
</script>

<style lang="scss" scoped>
.description-wrapper{
  display: flex;


  &__main{
    width: 510px;

    font-family: Raleway;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;

    margin-right: 150px;

    span{
      font-family: Raleway;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  &__btn{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 270px;
    height: 64px;

    margin-top: 48px;

    background: #1454F2;
    border-radius: 100px;

    cursor: pointer;
    &:hover{
      background-color: #4072ef;
    }
  }
  &__btn-title{
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    color: #FFFFFF;
    margin-right: 15px;
  }
  &__details{
    display: flex;
    flex-direction: column;

    padding-top: 18px;
  }
  &__detail{
    display: flex;
    align-items: center;

    min-width: 430px;
    margin-bottom: 24px;
  }
  &__detail-text{
    display: flex;
    align-items: center;

    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    margin-left: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .description-wrapper__main {
    margin-right: 50px;
    transition: 0.2s;
  }
}
@media screen and (max-width: 980px) {
  .description-wrapper {
    padding:  0px 24px;
    transition: 0.2s;
  }
  .description-wrapper__main{
    margin-right: 20px;
    transition: 0.2s;
    &-title{
      font-size: 26px;
      line-height: 42px;
      transition: 0.2s;

      span{
        font-size: 36px;
        transition: 0.2s;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .description-wrapper{
    display: flex;
    align-items: center;
    flex-direction: column;

    transition: 0.2s;
  }
  .description-wrapper__main{
    margin-right: 0px;
    margin-bottom: 34px;
    transition: 0.2s;

    &-title{
      text-align: center;
    }
  }
  .description-wrapper__details {
    padding-top: 0px;
    transition: 0.2s;
  }
  .description-wrapper__btn{
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 600px) {
  .description-wrapper__main {
    width: 100%;
    text-align: center;
    &-title {
      font-size: 22px;
      line-height: 42px;
      transition: 0.2s;

      span {
        font-size: 32px;
        transition: 0.2s;
      }
    }
  }
  .description-wrapper__detail{
    justify-content: center;
  }
}
@media screen and (max-width: 450px) {
  .description-wrapper__main {
    width: 100%;
    &-title {
      font-size: 16px;
      line-height: 24px;
      transition: 0.2s;

      span {
        font-size: 20px;
        line-height: 32px;
        transition: 0.2s;
      }
    }
  }
  .description-wrapper__detail-text{
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

    word-wrap: break-word;
    width: 250px;
    transition: 0.2s;
  }
  .description-wrapper__btn{
    transition: 0.2s;
  }
}
@media screen and (max-width: 430px) {
  .description-wrapper__detail{
    min-width: 300px;
  }
}
</style>