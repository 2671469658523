<template>
  <div class="courses-filter-wrapper"
       v-loading="loading"
       element-loading-spinner="el-icon-loading">

    <div class="courses-filter-wrapper__header">
      <div class="courses-filter-wrapper__title">{{ nameCategory }}</div>
      <div class="courses-filter-wrapper__count-courses">{{ numberCoursesCount }}</div>
    </div>
    <div class="courses-filter-wrapper__courses">

      <router-link :to="`/course-info/${item.id}`" class="courses-filter-wrapper__course"
           :style="{background: item.color}"
           v-for="(item,index) in getFilteredCourses"
           :key="index">

        <div class="courses-filter-wrapper__course-main">
          <div class="courses-filter-wrapper__course-main-header">
            <div class="courses-filter-wrapper__course-title">{{ item.name }}</div>

<!--            <div v-if="item.oldPrice === null || item.oldPrice === 0" class="courses-filter-wrapper__course-price">{{ item.price }}₽</div>-->
<!--            <div v-else class="courses-filter-wrapper__course-price-discount">-->
<!--              <div>-->
<!--                <div class="old-price">-->
<!--                  <span>{{item.oldPrice}}</span>&#8381;-->
<!--                </div>-->
<!--                <div class="new-price">-->
<!--                  <span>{{ item.price }}</span>&#8381;-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="discount">-->
<!--                {{discountPrice(item.oldPrice,item.price).toFixed(0)}}%-->
<!--              </div>-->
<!--            </div>-->

          </div>
          <div class="courses-filter-wrapper__course-footer">
            {{ numberVideosCount(item.lessonsCount || 0) }}
<!--            <div class="courses-filter-wrapper__course-footer-delimiter"/>-->
<!--            {{ numberTasksCount(item.tasksCount) }}-->
          </div>
        </div>
        <el-image class="courses-filter-wrapper__course-main-logo" :src="item.picture">
          <div slot="error">
            <img class="courses-filter-wrapper__course-main-logo" src="@/assets/img/user/no-courses.svg" alt="">
          </div>
        </el-image>
      </router-link>

      <el-empty class="courses-filter-wrapper__courses-empty" v-if="!getFilteredCourses.length"
                description="Курсов нет"/>

    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CoursesFilter",
  computed: {
    ...mapGetters(['getFilteredCourses']),
    selectedCategoryId() {
      return this.$store.state.courses.selectedCategoryId
    },
    searchCourseName() {
      return this.$store.state.courses.searchCourseName
    },
    nameCategory() {
      return this.selectedCategoryId ? this.$store.state.courses.categories.find(item => item.id === this.selectedCategoryId).name : 'Все направления'
    },
    numberCoursesCount() {
      let title = ''
      let number = this.getFilteredCourses.length
      let lastNumber = Number(number.toString()[number.toString().length - 1])


      if (lastNumber === 1)
        title = `${this.getFilteredCourses.length} курс`
      else if (lastNumber > 1 && lastNumber < 5)
        title = `${this.getFilteredCourses.length} курса`
      else if ((lastNumber >= 5 && lastNumber <= 9) || lastNumber === 0) {
        title = `${this.getFilteredCourses.length} курсов`
      }
      if (number >= 10 && number <= 20) {
        title = `${this.getFilteredCourses.length} курсов`
      }

      return title
    },
  },
  watch: {
    selectedCategoryId() {
      this.startPreloader()
    },
    searchCourseName() {
      this.startPreloader()
    },
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    startPreloader() {
      this.loading = true
      setTimeout(() => this.loading = false, 300)
    },
    numberVideosCount(videosCount) {
      let title = ''
      let lastNumber = Number(videosCount.toString()[videosCount.toString().length - 1])

      if (lastNumber === 1)
        title = `${videosCount} урок`
      else if (lastNumber > 1 && lastNumber < 5)
        title = `${videosCount} урока`
      else if ((lastNumber >= 5 && lastNumber <= 9) || lastNumber === 0) {
        title = `${videosCount} уроков`
      }
      if (videosCount >= 10 && videosCount <= 20)
        title = `${videosCount} уроков`

      return title
    },
    numberTasksCount(tasksCount) {
      let title = ''
      let lastNumber = Number(tasksCount.toString()[tasksCount.toString().length - 1])

      if (lastNumber === 1)
        title = `${tasksCount} задание`
      else if (lastNumber > 1 && lastNumber < 5)
        title = `${tasksCount} задания`
      else if ((lastNumber >= 5 && lastNumber <= 9) || lastNumber === 0) {
        title = `${tasksCount} заданий`
      }
      if (tasksCount >= 10 && tasksCount <= 20)
        title = `${tasksCount} заданий`

      return title
    },
    discountPrice(oldPrice, newPrice){
      const discount = (newPrice - oldPrice)/oldPrice * 100
      return discount < 0 ? discount * -1 : discount
    },
  }
}
</script>

<style lang="scss" scoped>
.courses-filter-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  padding-bottom: 95px;

  &__header {
    display: flex;
    align-items: center;
    max-width: 915px;

    margin-bottom: 32px;
    transition: 0.2s;
  }

  &__title {
    font-family: Raleway, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;

    white-space: nowrap; /* Запрещаем перенос строк */
    overflow: hidden; /* Обрезаем все, что не помещается в область */
    text-overflow: ellipsis; /* Добавляем многоточие */

    color: #121212;

    margin-right: 42px;
  }

  &__count-courses {
    font-family: Raleway, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;

    color: #121212;
  }

  &__courses {
    display: flex;
    flex-wrap: wrap;
  }

  &__course {
    position: relative;

    display: flex;
    justify-content: space-between;

    text-decoration: none;
    color: #121212;

    height: 132px;
    width: 382px;

    padding: 24px;
    margin-right: 25px;
    margin-bottom: 25px;

    border-radius: 20px;

    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      bottom: 50%;
      left: 0;
      width: 100%;
      height: 0;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 5px;
    }

    &:hover:before {
      transition: 400ms;
      bottom: 0;
      height: 100%;
    }

    &:nth-child(2n) {
      margin-right: 0px;
    }
  }

  &__course-title {
    font-family: Raleway, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__course-price {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    margin-bottom: 18px;
  }
  &__course-price-discount {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

    margin-bottom: 18px;

    transition: 0.2s;

    .old-price {
      font-size: 12px;

      span  {
        text-decoration: line-through;
        margin-right: 2px;
      }
    }

    .new-price {
      font-size: 18px;

      span {
        margin-right: 2px;
      }
    }

    .discount {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 12px;
      text-align: center;

      width: 42px;
      height: 26px;

      background: #FBF805;
      border-radius: 30px;

      margin-left: 10px;
    }
  }

  &__course-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-right: 5px;
    width: 60%;
    min-width: 160px;
    height: 100%;
  }

  &__course-main-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
  }

  &__course-main-logo {
    max-width: 172px;
    max-height: 130px;
    width: 100%;
    border-radius: 4px;
  }

  &__course-footer {
    display: flex;
    align-items: center;

    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  &__course-footer-delimiter {
    content: '';
    width: 4px;
    height: 4px;

    margin-left: 10px;
    margin-right: 10px;
    border-radius: 100%;
    background: #000000;
  }

}

@media screen and (max-width: 1200px) {
  .courses-filter-wrapper__header {
    max-width: 690px;
  }
  .courses-filter-wrapper__course {
    width: 285px;
    margin-right: 24px;
    margin-bottom: 22px;
    transition: 0.2s;
  }
  .courses-filter-wrapper__course-main-logo {
    max-width: 132px;
    max-height: 100px;
  }
}

@media screen and (max-width: 980px) {
  .courses-filter-wrapper__header {
    max-width: 100%;
  }
  .courses-filter-wrapper {
    padding-bottom: 70px;
    transition: 0.2s;
  }
  .courses-filter-wrapper__course-main-logo {
    max-width: 142px;
    max-height: 112px;
  }
  .courses-filter-wrapper__course {
    width: 370px;
    height: 142px;
    transition: 0.2s;
  }
  .courses-filter-wrapper__course-footer-category {
    max-width: 175px;
  }
  .courses-filter-wrapper__title {
    margin-right: 52px;
    transition: 0.2s;
  }
}

@media screen and (max-width: 910px) {
  .courses-filter-wrapper {
    padding-bottom: 70px;
    transition: 0.2s;
  }
  .courses-filter-wrapper__course {
    width: 340px;
  }
  .courses-filter-wrapper__course-footer-category {
    max-width: 175px;
  }
  .courses-filter-wrapper__title {
    margin-right: 52px;
    transition: 0.2s;
  }
}

@media screen and (max-width: 870px) {
  .courses-filter-wrapper__course {
    width: 322px;
    transition: 0.2s;
  }
  .courses-filter-wrapper__course-footer-category {
    max-width: 150px;
    transition: 0.2s;
  }
  .courses-filter-wrapper__course-main-logo {
    max-width: 142px;
    max-height: 112px;
  }
}

@media screen and (max-width: 820px) {
  .courses-filter-wrapper__course {
    width: 300px;
    transition: 0.2s;
  }
  .courses-filter-wrapper__course-footer-category {
    max-width: 125px;
    transition: 0.2s;
  }
}

@media screen and (max-width: 770px) {
  .courses-filter-wrapper__course {
    width: 500px;
    margin-right: 0px;
    transition: 0.2s;
  }
}

@media screen and (max-width: 600px) {
  .courses-filter-wrapper {
    padding-bottom: 0px;
    transition: 0.2s;
  }
  .courses-filter-wrapper__course {
    width: 100%;
    margin-right: 0px;
    transition: 0.2s;
  }
  .courses-filter-wrapper__title {
    font-size: 28px;
    margin-right: 30px;
    transition: 0.2s;
  }
  .courses-filter-wrapper__count-courses {
    font-size: 16px;
    line-height: 24px;
    transition: 0.2s;
  }
}

@media screen and (max-width: 525px) {
  .courses-filter-wrapper__course-footer-category {
    max-width: 200px;
    transition: 0.2s;
  }
}

@media screen and (max-width: 420px) {
  .courses-filter-wrapper__course {
    height: 140px;
  }
  .courses-filter-wrapper__course-price, .courses-filter-wrapper__course-price-discount {
    margin-bottom: 2px;
  }
}

@media screen and (max-width: 400px) {
  .courses-filter-wrapper__course {
    height: 115px;
    padding-left: 14px;
    padding-top: 14px;
    transition: 0.2s;
  }
  .courses-filter-wrapper__course-main-logo {
    max-width: 105px;
    max-height: 75px;
    transition: 0.2s;
  }
  .courses-filter-wrapper__course-title {
    font-size: 14px;
    line-height: 20px;
    transition: 0.2s;
  }
  .courses-filter-wrapper__course-price {
    font-size: 16px;
    line-height: 20px;
    transition: 0.2s;
  }
  .courses-filter-wrapper__course-price-discount {
    line-height: 20px;

    .new-price {
      font-size: 16px;
    }
    .discount {
      font-size: 11px;
      width: 40px;
    }
  }
  .courses-filter-wrapper__title {
    font-size: 20px;
    margin-right: 24px;
    line-height: 32px;
    transition: 0.2s;
  }
  .courses-filter-wrapper__count-courses {
    font-size: 16px;
    line-height: 20px;
    transition: 0.2s;
  }
  .courses-filter-wrapper__count-courses {
    font-size: 14px;
    transition: 0.2s;
  }

  .courses-filter-wrapper__course-title {
    -webkit-line-clamp: 2;
  }
}

@media screen and (max-width: 360px) {
  .courses-filter-wrapper__course-title {
    font-size: 14px;
    line-height: 20px;
  }
  .courses-filter-wrapper__course {
    padding: 14px;
    transition: 0.2s;
  }
}
</style>

<style lang="scss">
.courses-filter-wrapper .el-loading-mask {
  .el-icon-loading {
    font-size: 26px;
    color: #1454F2;
  }
}

.courses-filter-wrapper__courses-empty {
  margin: 0 auto;

  .el-empty__description {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
  }
}

.courses-filter-wrapper__course {
  .el-image {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .el-image__inner {
    object-fit: cover;
    border-radius: 4px;
  }
}
</style>
