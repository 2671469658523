<template>
  <div class="categories-wrapper">
    <div class="categories-wrapper__title">Направления</div>
    <div class="categories-wrapper__category"
         :class="{'active': item.id === selectedCategoryId}"
         v-for="(item,index) in categories" :key="index"
         @click="handleClickCategory(item)">
      <div class="categories-wrapper__category-name">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Categories",
  computed: {
    categories() {
      return this.$store.state.courses.categories
    },
    selectedCategoryId() {
      return this.$store.state.courses.selectedCategoryId
    }
  },
  created() {
    if(this.$store.state.lastRoute.name !== 'home' || !this.selectedCategoryId) {
      this.$store.commit('courses.setSelectedCategory', this.categories?.[0].id)
    }
  },
  methods: {
    handleClickCategory(category) {
      this.$store.commit('courses.setSelectedCategory', category.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.categories-wrapper {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 200px;

  margin-right: 85px;

  &__title {
    display: none;

    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    margin-bottom: 20px;

    flex-basis: 100%;
  }

  &__category {
    display: flex;
    justify-content: center;
    align-items: center;

    width: max-content;
    max-width: 100%;

    padding: 12px 24px;

    height: 40px;

    border: 1px solid #1454F2;
    box-sizing: border-box;
    border-radius: 120px;
    background: #FFFFFF;

    margin-bottom: 12px;
    cursor: pointer;

    transition: 200ms;

    &.active, &:hover {
      background-color: #1454F2;

      > div {
        color: #FFFFFF !important;
      }
    }

  }

  &__category-name {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;


    color: #121212;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media screen and (max-width: 980px) {
  .categories-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;

    &__title{
      display: block;
    }
  }
  .categories-wrapper__category{
    margin-right: 18px;
  }
}

@media screen and (max-width: 600px) {
  .categories-wrapper__title {
    font-size: 28px;
    margin-right: 42px;
    transition: 0.2s;
  }
}

@media screen and (max-width: 400px) {
  .categories-wrapper__title {
    font-size: 20px;
    line-height: 24px;
    transition: 0.2s;
  }
}
</style>
